<template>
  <v-dialog v-model="dialog" persistent max-width="650px">
    <v-card>
      <v-card-title>
        <span class="headline">Расписание</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <div class="block-popupform-content_part">
            <div class="block-popupform-fields">
              <div class="im-option">
                <v-text-field
                  v-model.lazy="name"
                  :rules="serverRules"
                  label="Название*"
                  outlined
                  required
                ></v-text-field>
              </div>
              <table class="editable-table">
                <thead>
                  <tr>
                    <th style="width: 200px">Рабочие дни</th>
                    <th style="width: 150px">Работа</th>
                    <th>Перерыв</th>
                    <th>Часы</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="block-scheduleiteminput block-base">
                    <td>
                      <span>
                        <input type="checkbox" v-model="checkAll" />
                      </span>
                      <span class="block-button-value">Единое</span>
                    </td>
                    <td>
                      <TimeRangePicker v-model="timeRange"></TimeRangePicker>
                    </td>
                    <td>
                      <TimeRangePicker v-model="restRange"></TimeRangePicker>
                    </td>
                    <td>
                      <div id="i662" class="block-inputtime-result">
                        <input
                          type="text"
                          spellcheck="true"
                          class="block-inputbase-input block-input-field"
                          readonly
                          :value="
                            timeres({ range: timeRange, rest: restRange })
                          "
                        />
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-for="item in weeklist"
                    :key="item"
                    class="block-scheduleiteminput block-base"
                  >
                    <td>
                      <span
                        ><input type="checkbox" v-model="item.check" />
                      </span>
                      <span class="block-button-value">{{ item.title }}</span>
                    </td>
                    <td>
                      <TimeRangePicker
                        v-model="item.range"
                        :disabled="checkAll"
                      ></TimeRangePicker>
                    </td>
                    <td>
                      <TimeRangePicker
                        v-model="item.rest"
                        :disabled="checkAll"
                      ></TimeRangePicker>
                    </td>
                    <td>
                      <div id="i662" class="block-inputtime-result">
                        <input
                          type="text"
                          spellcheck="true"
                          class="block-inputbase-input block-input-field"
                          readonly
                          :value="timeres(item)"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close">{{ $ml.get("button.close") }}</v-btn>
        <v-btn color="primary" text @click="store">{{
          $ml.get("button.save")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import TimeRangePicker from "../../components/TimeRangePicker";

export default {
  data() {
    return {
      dialog: true,
      name: "",
      checkAll: false,
      timeRange: "08:00-17:00",
      restRange: "",
      inp: "",
      weeklist: [
        {
          name: "mon",
          title: "Понедельник",
          range: "",
          rest: "",
          check: false,
        },
        { name: "tue", title: "Вторник", range: "", rest: "", check: false },
        { name: "wed", title: "Среда", range: "", rest: "", check: false },
        { name: "thu", title: "Четверг", range: "", rest: "", check: false },
        { name: "fri", title: "Пятница", range: "", rest: "", check: false },
        { name: "sat", title: "Суббота", range: "", rest: "", check: false },
        {
          name: "sun",
          title: "Воскресенье",
          range: "",
          rest: "",
          check: false,
        },
      ],
    };
  },
  components: {
    TimeRangePicker,
  },
  methods: {
    store() {},
    close() {
      this.dialog = false;
    },
    timeres(item) {
      let resrmin = 0;
      let resmin = 0;
      if (item.rest) {
        let vr = item.rest.trim().split("-");
        let rm1 = vr[0].split(":");
        let rm2 = vr[1].split(":");
        rm1 = parseInt(rm1[0]) * 60 + parseInt(rm1[1]);
        rm2 = parseInt(rm2[0]) * 60 + parseInt(rm2[1]);
        resrmin = rm2 - rm1;
        if (resrmin < 0) {
          resrmin += 1440;
        }
      }
      if (item.range) {
        let v = item.range.trim().split("-");
        let tm1 = v[0].split(":");
        let tm2 = v[1].split(":");
        tm1 = parseInt(tm1[0]) * 60 + parseInt(tm1[1]);
        tm2 = parseInt(tm2[0]) * 60 + parseInt(tm2[1]);
        resmin = tm2 - tm1;
        if (resmin < 0) {
          resmin += 1440;
        }
        resmin -= resrmin;

        if (resmin) {
          return (
            Math.floor(resmin / 60)
              .toString()
              .padStart(2, "0") +
            ":" +
            (resmin % 60).toString().padStart(2, "0")
          );
        }
      }
    },
    calcFor() {
      this.weeklist.forEach((item, i) => {
        if (item.check) {
          this.weeklist[i].range = this.timeRange;
          item.rest = this.restRange;
        }
      });
    },
  },
  watch: {
    timeRange(val) {
      this.calcFor();
    },
    restRange(val) {
      this.calcFor();
    },
  },
};
</script>
<style>
.editable-table {
  width: 100%;
}
.editable-table th {
  padding: 5px;
}
.editable-table td {
  padding: 8px;
}
.editable-table .block-inputtime-result input {
  width: 100%;
}
.block-button-value {
  margin-left: 10px;
}
</style>
